.mandatClauses__container{
    padding: 0.5rem;
}

.mandatClauses__itemContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
}
.mandatClause__content{
    flex: 1;
    margin: 0 0.5rem
}

.mandatClause__dragIcon{
    cursor: grab;
    color: #e8e8e8;
}
.mandatClause__dragIcon:active {
    cursor: grabbing;
    color: blue;
}

.mandatClause__deleteIcon{
    cursor: pointer;
    color: #e8e8e8;
}
.mandatClause__deleteIcon:hover {
    color: red;
}