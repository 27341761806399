.dateRange{
    display: flex;
    z-index: 10;
    
}

.dateRangeBtn{
    /* display: flex; */
    margin: 0px;
    text-align: center;
    padding: 10px 20px;
    justify-self: self-end;
    background-color: #022f67;
    border: 2px solid #022f67;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.dateRangeBtn:hover{
    transition: 0.2s all ease;
    background-color: white;
    color: #022f67;
}
.dateRangeBtn:focus{
    outline: none;
}