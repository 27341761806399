.accountRequest__container{
    min-height: 100vh;
    background-image: url("../../assets/images/login_background.jpg");
    background-size: cover;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountRequest__FromWrap{
    background-color: white;
    width: 50%;
    /* height: 90%; */
    margin: 2rem 0;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
    padding: 1rem;
}

.accountRequest__header{
    display: flex;
    justify-content: center;
    margin: 1rem 0 2rem 0;
}

.accountRequest__Icon {
    max-width: 75px;
    max-height: 100px;
}

.accountRequest__Icon img{
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.accountRequest__form {
    display: flex;
    width: 100%;
    padding: 0 2rem;
    font-size: 0.8rem;
    flex-direction: column;
    justify-content: center;
}

.accountRequest__formGroup{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.accountRequest__formInput{
    padding: 0.5rem;
    /* margin-bottom: 32px; */
    border: none;
    border-bottom: 1px solid #F5FBFF;
    color: #482D1C;
    background-color: #ffffff;
    /* border-bottom:2px solid rgb(25, 98, 158) ; */
    /* border-radius: 9px; */
    outline: none;
}
.accountRequest__formInput::placeholder{
    color: #482D1C;
}
.accountRequest__formInput:focus{
    outline: none;
    border-bottom: 1px solid #2174B9;
    color: #2174B9;
}
.accountRequest__formInput:focus::placeholder{
    color: #2174B9;
}

.accountRequest__canalContainer{
    width: 100%;
    border-bottom: 1px solid #F5FBFF;
    /* padding-bottom: 1rem; */
}

.accountRequest__canalLabel{
    color: #482D1C;
    padding-left: 0.5rem;
    font-size: 00.9rem;
    margin: 0.5rem 0;
}

.accountRequest__canalWrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border: 1px solid #482D1C66;
    border-radius: 18px;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.accountRequest__canalItem{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 15%;
    color: #482D1C;
}
.accountRequest__canalItemLabel{
    margin: 0;
}
.activeToggle{
    color: #00B894;
}

.accountRequest__canalItemValueOnIcon{
    color: #00B894;
}

.accountRequest__entitiesList{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.accountRequest__entitiesListItem{
    padding: 0.25rem 0.5rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 90px;
    background-color: #2174B980;
    border-radius: 8px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.accountRequest__entitiesListItem_label{
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accountRequest__entitiesListItem_icon{
    cursor: pointer;
}

.accountRequest__entitiesListItem_icon:hover{
    color: red;
}

.accountRequest__formButtons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
}

.accountRequest__cancelButton{
    background-color: #ffffff;
    color: #482D1C;
    padding: 1rem;
    border: 2px solid #482D1C;
    cursor: pointer;
    border-radius: 16px;
    margin-left: 1rem;
    width: 152px;
    text-align: center;
}

/* .accountRequest__cancelButton:hover{
    color: #6C5CE7;
    border: 2px solid #6C5CE7;
} */
.accountRequest__cancelButton:hover{
    color: #ffffff;
    background-color: #482D1C;
}

.accountRequest__sendButton{
    background-color: #ffffff;
    color: #6C5CE7;
    padding: 1rem;
    border: 2px solid #6C5CE7;
    cursor: pointer;
    border-radius: 16px;
    margin-left: 2rem;
    width: 152px;
}

.accountRequest__sendButton:hover{
    color: #ffffff;
    background-color: #6C5CE7;
}

.accountRequest__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.accountRequest__footer p {
    color: black;
    font-weight: 600;
}