.entitiesDropDown__container{
    width: 100%;
    margin: 0.5rem 0;
}

.entitiesDropDown__label{
    font-size: 0.9rem;
    color: #482D1C;
    padding-left: 0.5rem;
}

.entitiesDropDown__addBtn{
    cursor: pointer;
    background-color: #F5FBFF ;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem;
}

.entitiesDropDown__addBtn__label{
    color: #2174B9;
}
.entitiesDropDown__addBtn__icon{
    margin-right: 1rem;
}
.entitiesDropDown__wrap{
    width: 100%;
    position: relative;
    z-index: 100;
}
.entitiesDropDown__search{
    background-color: #F5FBFF ;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.25rem 0.5rem;
}
.entitiesDropDown__searchInput{
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border: none;
    border-bottom: 1px solid #F5FBFF;
    color: #482D1C;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex: 1;
    background-color: #efe8df;
}
.entitiesDropDown__searchInput::placeholder{
    color: #2174B9;
}
.entitiesDropDown__searchInput:focus{
    outline: none;
    border-bottom: 1px solid #2174B9;
    color: #2174B9;
}
.entitiesDropDown__searchInput:focus::placeholder{
    color: #2174B9;
}
.entitiesDropDown__search__closeIcon{
    cursor: pointer;
    margin-right: 1rem;
}
.entitiesDropDown__search__closeIcon:hover{
    color: #2174B9;
}

.entitiesDropDown__content{
    position: absolute;
    width: 100%;
    background-color: #efe8df;
    font-size: 0.9rem;
    margin: 0 0.25rem;
}

.entitiesDropDown__content__header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem ;
    color: #482D1C;
}

.entitiesDropDown__content__items{
    height: 100px;
    overflow-y: auto;
}

.entitiesDropDown__content__item{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 600;

}
.entitiesDropDown__content__item:hover{
    background-color: rgba(128, 159, 184, 0.5);
}
.entitiesDropDown__content__item__code{
    color: #2174B9;
}