.signatureTab__step {
    margin-bottom: 1.5rem;
}

.singatureTab__step_header {
    display: flex;
    align-items: center;
}

.signatureTab__stepNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: rgb(0, 121, 188);
    color: #FFFFFF;
    margin-right: 1rem;
}

.messageTab__number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: rgb(226, 47, 42);
    color: #FFFFFF;
    margin-right: 1rem;
}

.nav-item:hover{
    cursor: pointer;
}
.step__header__title {
    font-size: 0.8rem;
    font-weight: 600;
    min-width: 300px;
    display: flex;
} 
.step__checkbox__label {
    margin: 0;
    color: rgb(0, 121, 188);
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 0.5rem;
}
.stepContent__identityContent {
    margin-left: 5rem;
    width: 200px;
}

.signatureTab__stepDoneIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    min-width: 37px;
    min-height: 37px;
    border-radius: 50%;
    background-color: rgba(77, 202, 118, 1);
    color: #FFFFFF;
    margin-right: 1rem;
}

.signatureTab__stepCheckbox__container {
    display: flex;
    align-items: center;
}